import React, { useState, useContext, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { navigate, graphql, useStaticQuery } from 'gatsby'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import theme from 'src/utils/styles/theme'
import YotpoStars from 'src/components/YotpoStars'
import Analytics from 'src/utils/Analytics'
import LocalStorage from 'src/utils/LocalStorage'
import { isMobile } from 'react-device-detect'
import {
  FramePriceByColor,
  DefaultFrameColor,
  GetFrameDiscountDetails,
  GetCtaDetails,
} from 'src/utils/FrameProductPage'
import {
  currencyCode,
  IS_FOREIGN_COUNTRY,
  HIDE_PLUS,
  IN_CA,
  IN_AU,
  IN_UK,
  IN_EUROPE,
} from 'src/utils/constants'
import Helmet from 'react-helmet'
import StoreContext from '../../context/StoreContext'
import AddToCartButton from '../AddToCartButton'
import ShippingDetails from '../ShippingDetails'
import {
  DISPLAY_BOGO,
  FRAME_VARIANTS,
  LIMITED_EDITION_VARIANTS,
  BUY_WITH_PRIME_SKU,
  BUY_WITH_PRIME_SKU_VARIANT_SKUS,
  AMAZON_LINKS,
} from './constants'
import ColorSelectionItem from '../ColorSelectionItem'
import {
  Quantity,
  QuantityInput,
  QuantityButton,
  InternationalQuantity,
  InternationalQuantityLabel,
  InternationalQuantityInput,
  PricePoint,
  Header,
  Subheader,
  ButtonContainer,
  Centered,
  AddToCartCtaButton,
  FrameProductOptions,
  FrameSelection,
  FrameSizeContainer,
  FramePrice,
  FramePriceStrike,
  PriceContainer,
  CustomizationOption,
  Text,
  BuyWithPrimeContainer,
  OutOfStock,
  LimitedEdition,
  RedSubheader,
  Bogo,
  BlackFridayBubble,
  CtaContainer,
} from './styles'
import StickyButton from '../StickyButton'
import { FormattedCurrency } from '../../utils/FormattedCurrency'

const defaultLanguage = process.env.GATSBY_I18N_DEFAULT_LANGUAGE

const FrameProductForm = ({
  product,
  simplifiedProductTitle,
  handle,
  title,
  frame15,
  frameBlack,
  frameWhite,
  frameSilver,
  frameGold,
  framePoppy,
  isLargerDevice,
  isPlusProduct,
  setShownImageIndex,
  updateSelectedColor,
}) => {
  const { t } = useTranslation('common')

  const [allowBuyWithPrimeScript, setAllowBuyWithPrimeScript] = useState(false)
  const [buyWithPrimeLoaded, setBuyWithPrimeLoaded] = useState(false)
  useEffect(() => {
    if (!allowBuyWithPrimeScript) {
      setAllowBuyWithPrimeScript(true)
      return
    }

    // artificial await for buy with prime script
    const timeout = setTimeout(() => setBuyWithPrimeLoaded(true), 1000)
    return () => clearTimeout(timeout)
  }, [allowBuyWithPrimeScript])

  const {
    contentful10FramePrice,
    contentful15FramePrice,
    buyWithPrimeData,
    contentfulCTAOverrides,
  } = useStaticQuery(graphql`
    query FrameProductForm {
      contentful10FramePrice: allContentful10InFramePriceStrikethrough {
        edges {
          node {
            en_original_price
            en_discounted_price
            en_discount_bubble
            en_original_price_White
            en_discounted_price_White
            en_discount_bubble_White
            en_original_price_Silver
            en_discounted_price_Silver
            en_discount_bubble_Silver
            en_original_price_Gold
            en_discounted_price_Gold
            en_discount_bubble_Gold
            en_original_price_Poppy
            en_discounted_price_Poppy
            en_discount_bubble_Poppy
            en_ca_original_price
            en_ca_discounted_price
            en_ca_discount_bubble
            en_au_original_price
            en_au_discounted_price
            en_au_discount_bubble
            en_gb_original_price
            en_gb_discounted_price
            en_gb_discount_bubble
            en_eu_original_price
            en_eu_discounted_price
            en_eu_discount_bubble
            es_original_price
            es_discounted_price
            es_discount_bubble
            fr_original_price
            fr_discounted_price
            fr_discount_bubble
            de_original_price
            de_discounted_price
            de_discount_bubble
            it_original_price
            it_discounted_price
            it_discount_bubble
            se_original_price
            se_discounted_price
            se_discount_bubble
            nl_original_price
            nl_discounted_price
            nl_discount_bubble
            pl_original_price
            pl_discounted_price
            pl_discount_bubble
          }
        }
      }
      contentful15FramePrice: allContentful15InFramePriceStrikethrough {
        edges {
          node {
            en_original_price
            en_discounted_price
            en_discount_bubble
            en_ca_original_price
            en_ca_discounted_price
            en_ca_discount_bubble
            en_au_original_price
            en_au_discounted_price
            en_au_discount_bubble
            en_gb_original_price
            en_gb_discounted_price
            en_gb_discount_bubble
            en_eu_original_price
            en_eu_discounted_price
            en_eu_discount_bubble
            es_original_price
            es_discounted_price
            es_discount_bubble
            fr_original_price
            fr_discounted_price
            fr_discount_bubble
            de_original_price
            de_discounted_price
            de_discount_bubble
            it_original_price
            it_discounted_price
            it_discount_bubble
            se_original_price
            se_discounted_price
            se_discount_bubble
            nl_original_price
            nl_discounted_price
            nl_discount_bubble
            pl_original_price
            pl_discounted_price
            pl_discount_bubble
          }
        }
      }
      buyWithPrimeData: allContentfulBuyWithPrime {
        edges {
          node {
            Black
            Black_15
            Gold
            Poppy
            Silver
            White
            show_sticky_cta
            default_frame_product
            show_bwp_cta_first
          }
        }
      }
      contentfulCTAOverrides: allContentfulCtaCopyOnFrameAndCalendarPdp {
        edges {
          node {
            de_10_frame
            de_15_frame
            en_10_frame_Black
            en_10_frame_Gold
            en_10_frame_Poppy
            en_10_frame_Silver
            en_10_frame_White
            en_15_frame_Black
            en_au_10_frame
            en_au_15_frame
            en_ca_10_frame
            en_ca_15_frame
            en_eu_10_frame
            en_eu_15_frame
            en_gb_10_frame
            en_gb_15_frame
            es_10_frame
            es_15_frame
            fr_10_frame
            fr_15_frame
            it_10_frame
            it_15_frame
            nl_10_frame
            nl_15_frame
            pl_10_frame
            pl_15_frame
            se_10_frame
            se_15_frame
          }
        }
      }
    }
  `)

  const frame15Price = frame15.data.shopifyProduct.variants[0].price

  const [frame10Price, setFrame10Price] = useState(frameBlack.data.shopifyProduct.variants[0].price)
  const BUY_WITH_PRIME_INFO = buyWithPrimeData.edges[0].node
  const default10frameColor =
    BUY_WITH_PRIME_INFO.default_frame_product === '15" Black'
      ? 'Black'
      : BUY_WITH_PRIME_INFO.default_frame_product
  const showBwpCtaFirst = BUY_WITH_PRIME_INFO.show_bwp_cta_first

  const [selectedColor, setSelectedColor] = useState(DefaultFrameColor(handle, default10frameColor))

  const [showStickyButton, setShowStickyButton] = useState(true)
  function isElementVisible(el) {
    if (!el) return false
    const rect = el.getBoundingClientRect()
    const windowHeight = window.innerHeight || document.documentElement.clientHeight
    const windowWidth = window.innerWidth || document.documentElement.clientWidth
    const vertInView = rect.top <= windowHeight && rect.top + rect.height >= 0
    const horInView = rect.left <= windowWidth && rect.left + rect.width >= 0
    return vertInView && horInView
  }

  const [selectedFifteenProduct, setSelectedFifteenProduct] = useState(
    handle === 'the-skylight-frame-15' ||
      (!IS_FOREIGN_COUNTRY && BUY_WITH_PRIME_INFO.default_frame_product === '15" Black')
  )
  const [selectedSku, setSelectedSku] = useState(
    selectedFifteenProduct ? BUY_WITH_PRIME_SKU[15].Black : BUY_WITH_PRIME_SKU[10][selectedColor]
  )

  const DISPLAY_BUY_WITH_PRIME = selectedFifteenProduct
    ? BUY_WITH_PRIME_INFO.Black_15
    : BUY_WITH_PRIME_INFO[selectedColor]

  const frame10Product = {
    Black: frameBlack.data.shopifyProduct?.variants[0],
    Silver: frameSilver.data.shopifyProduct?.variants[0],
    White: frameWhite.data.shopifyProduct?.variants[0],
    Gold: frameGold.data.shopifyProduct?.variants[0],
    Poppy: framePoppy.data.shopifyProduct?.variants[0],
  }

  const frame10Titles = {
    Black: frameBlack.data.shopifyProduct?.title,
    Silver: frameSilver.data.shopifyProduct?.title,
    White: frameWhite.data.shopifyProduct?.title,
    Gold: frameGold.data.shopifyProduct?.title,
    Poppy: framePoppy.data.shopifyProduct?.title,
  }

  const frameProduct = !selectedFifteenProduct
    ? frame10Product[selectedColor]
    : frame15.data.shopifyProduct.variants[0]

  const productName = !selectedFifteenProduct
    ? frame10Titles[selectedColor]
    : frame15.data.shopifyProduct.title

  const productInfo = {
    frame: frameProduct,
    name: productName,
    other: product.variants[0],
  }

  const [open, setOpen] = useState(false)

  const [isFixed, setIsFixed] = useState(true)
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 900 || window.pageYOffset < 150) {
        setIsFixed(true)
      } else {
        setIsFixed(false)
      }
      const regularButton = document.querySelector('.regular-cta')
      const regularButtonVisible = isElementVisible(regularButton)
      setShowStickyButton(!regularButtonVisible)
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const {
    store: { cart },
    addToCart,
  } = useContext(StoreContext)

  const [quantityVal, setQuantity] = useState(1)
  const [submitting, setSubmitting] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState('standard')

  const KLAVIYO_WAITLIST = {
    'en-eu': 'UXdzwg',
    de: 'VpfTin',
    nl: 'VrSTmG',
    se: 'USrkZd',
    es: 'Yw2n9Z',
    fr: 'Sbvp2y',
    it: 'SpJ9V8',
    pl: 'XvdMfk',
  }
  useEffect(() => {
    isLargerDevice(selectedFifteenProduct)
  }, [selectedFifteenProduct, isLargerDevice])

  useEffect(() => {
    if (!IS_FOREIGN_COUNTRY && buyWithPrimeLoaded) {
      try {
        window?.bwp?.updateWidget(selectedSku, quantityVal, BUY_WITH_PRIME_SKU_VARIANT_SKUS)
      } catch (error) {
        console.warn('Buy with prime update failed.', error)
      }
    }
  }, [selectedSku, quantityVal])

  useEffect(() => {
    isPlusProduct(false)
  }, [selectedProduct, isPlusProduct])

  const priceRef = useRef(null)
  const scrollToPrice = () => priceRef.current.scrollIntoView()
  const setSelectedProductAndScroll = (product) => {
    setSelectedProduct(product)
    scrollToPrice()
  }
  const setInitialNumFramesAdded = (q) => {
    let initialFrameQuantity = 0
    const existingFrameLineItem = cart.lines.edges.find(
      (el) => el.node.merchandise.id === productInfo.shopifyId
    )
    if (existingFrameLineItem) {
      initialFrameQuantity = existingFrameLineItem.node.quantity
    }
    LocalStorage.setItem('originalFrameQuantity', initialFrameQuantity + q)
  }

  const fireAnalytics = (deviceSize) => {
    Analytics.track(`Viewed ${deviceSize} Toggle Product Page`)
  }

  const fireframeColorAnalytics = (color) => {
    Analytics.track(`Viewed ${color} Frame Toggle Product Page`)
  }

  useEffect(() => {
    if (!IS_FOREIGN_COUNTRY && simplifiedProductTitle === 'Frame') {
      window.addEventListener('blur', () => {
        window.setTimeout(() => {
          if (document.activeElement instanceof HTMLIFrameElement) {
            Analytics.track(
              `Added ${
                selectedFifteenProduct ? '15"' : '10"'
              } ${selectedColor} Frame with bwprime to Cart`
            )
          }
        }, 0)
      })
    }
  }, [])

  const handleAddToCart = async (variantId, quantity) => {
    const id = variantId.split('/').pop()
    const pushToDataLayer = () => {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode,
          add: {
            products: [
              {
                id,
                name: productInfo.name,
                category: 'Frame',
                price: productInfo.frame.price,
                quantity,
              },
            ],
          },
        },
      })
    }

    if (!submitting) {
      setSubmitting(true)
      Analytics.track(`Added ${simplifiedProductTitle} to Cart`)
      if (simplifiedProductTitle === 'Frame' && !selectedFifteenProduct) {
        Analytics.track(`Added ${selectedColor} 10" Frame to Cart`)
      }
      setInitialNumFramesAdded(quantity)
      const items = [{ variantId, quantity }]

      const resp = await addToCart(items)
      if (resp && resp.error) {
        setSubmitting(false)
        alert(resp.error)
      } else {
        pushToDataLayer(variantId, productInfo, quantity)
        if (simplifiedProductTitle !== 'Frame' || HIDE_PLUS) {
          navigate('/cart/')
        } else {
          navigate('/products/skylight-plus/')
        }
      }
    }
  }
  const incrementQuantity = () => {
    setQuantity(quantityVal + 1)
    if (!IS_FOREIGN_COUNTRY && buyWithPrimeLoaded) {
      try {
        window?.bwp?.updateWidget(selectedSku, quantityVal + 1, BUY_WITH_PRIME_SKU_VARIANT_SKUS)
      } catch (error) {
        console.warn('Buy with prime update failed.', error)
      }
    }
  }
  const decrementQuantity = () => {
    if (quantityVal === 1) {
      return
    }
    setQuantity(quantityVal - 1)
    if (!IS_FOREIGN_COUNTRY && buyWithPrimeLoaded) {
      try {
        window?.bwp?.updateWidget(selectedSku, quantityVal - 1, BUY_WITH_PRIME_SKU_VARIANT_SKUS)
      } catch (error) {
        console.warn('Buy with prime update failed.', error)
      }
    }
  }

  useEffect(() => {
    updateSelectedColor(selectedColor)
    if (!IS_FOREIGN_COUNTRY) {
      const frameOptions = {
        Poppy: framePoppy,
        Black: frameBlack,
        Silver: frameSilver,
        White: frameWhite,
        Gold: frameGold,
      }
      const price = FramePriceByColor(selectedColor, frameOptions)
      setFrame10Price(price)
    }
  }, [
    updateSelectedColor,
    selectedColor,
    framePoppy,
    frameBlack,
    frameSilver,
    frameWhite,
    frameGold,
  ])

  const DisplayBWP = !IS_FOREIGN_COUNTRY

  // Strikethrough Price for 10" & 15" Frame is managed in contentful
  const lang = defaultLanguage.replace('-', '_')
  const frame10Prices = contentful10FramePrice.edges[0].node
  const frame15Prices = contentful15FramePrice.edges[0].node

  const frame10DiscountDetails = GetFrameDiscountDetails(
    frame10Prices,
    lang,
    selectedColor,
    selectedColor !== 'Black'
  )
  const frame15DiscountDetails = GetFrameDiscountDetails(frame15Prices, lang, selectedColor, false)
  const is10Discounted = frame10DiscountDetails.isDiscounted
  const orig10Price = frame10DiscountDetails.origPrice
  const disc10Price = frame10DiscountDetails.discPrice
  const disc10BubbleCopy = frame10DiscountDetails.discBubbleCopy

  const is15Discounted = frame15DiscountDetails.isDiscounted
  const orig15Price = frame15DiscountDetails.origPrice
  const disc15Price = frame15DiscountDetails.discPrice
  const disc15BubbleCopy = frame15DiscountDetails.discBubbleCopy

  const ctaDetails = GetCtaDetails(
    lang,
    false,
    selectedFifteenProduct ? 15 : 10,
    contentfulCTAOverrides.edges[0].node,
    selectedColor
  )
  const { CTA_VALUE, isOutOfStock, isPreorder } = ctaDetails

  const handleColorSelection = (variant) => {
    fireframeColorAnalytics(variant)
    setShownImageIndex(0)
    setSelectedColor(variant)
    setSelectedSku(BUY_WITH_PRIME_SKU[10][variant])
    if (!IS_FOREIGN_COUNTRY && buyWithPrimeLoaded) {
      try {
        window?.bwp?.updateWidget(
          BUY_WITH_PRIME_SKU[10][variant],
          quantityVal,
          BUY_WITH_PRIME_SKU_VARIANT_SKUS
        )
      } catch (error) {
        console.warn('Buy with prime update failed.', error)
      }
    }
    // window.scrollTo({
    //   top: 0,
    //   behavior: 'smooth',
    // })
  }

  return (
    <>
      {!IS_FOREIGN_COUNTRY && allowBuyWithPrimeScript && (
        <Helmet>
          <script
            defer
            src="https://code.buywithprime.amazon.com/default.variant.selector.shopify.js"
          />
          <script async src="https://code.buywithprime.amazon.com/bwp.v1.js" />
        </Helmet>
      )}
      <Centered>
        {simplifiedProductTitle === 'Calendar' ? (
          <YotpoStars color={theme.gold} size={18} productId="6863425175604" />
        ) : (
          <YotpoStars color={theme.gold} size={18} productId="7073751498804" />
        )}
      </Centered>
      <Header>
        <Trans>The Skylight Frame</Trans>
      </Header>

      <CustomizationOption>
        <Subheader>
          <Trans>Pick a size</Trans>
        </Subheader>
        <FrameProductOptions>
          <FrameSelection
            wide={is10Discounted || is15Discounted}
            blue={!selectedFifteenProduct}
            onClick={() => {
              setSelectedFifteenProduct(false)
              fireAnalytics('10in Frame')
              setSelectedColor(IS_FOREIGN_COUNTRY ? 'Black' : default10frameColor)
              setSelectedSku(BUY_WITH_PRIME_SKU[10].White)
              setShownImageIndex(0)
            }}
          >
            <FrameSizeContainer>
              <Text>
                <Trans>10 inch</Trans>
              </Text>
            </FrameSizeContainer>
            {is10Discounted ? (
              <PriceContainer>
                <FramePriceStrike>{FormattedCurrency(orig10Price)}</FramePriceStrike>
                <FramePrice red>{FormattedCurrency(disc10Price)}</FramePrice>
              </PriceContainer>
            ) : (
              <FramePrice>{FormattedCurrency(frame10Price)}</FramePrice>
            )}
            {disc10BubbleCopy && disc10BubbleCopy !== 'NONE' && (
              <BlackFridayBubble fifteen={selectedFifteenProduct}>
                {disc10BubbleCopy}
              </BlackFridayBubble>
            )}
          </FrameSelection>
          <FrameSelection
            wide={is10Discounted || is15Discounted}
            blue={selectedFifteenProduct}
            onClick={() => {
              setSelectedFifteenProduct(true)
              fireAnalytics('15in Frame')
              setShownImageIndex(0)
              setSelectedSku(BUY_WITH_PRIME_SKU[15].Black)
              setSelectedColor('Black')
            }}
          >
            <FrameSizeContainer>
              <Text>
                <Trans>15 inch</Trans>
              </Text>
            </FrameSizeContainer>
            {is15Discounted ? (
              <>
                <PriceContainer>
                  <FramePriceStrike>{FormattedCurrency(orig15Price)}</FramePriceStrike>
                  <FramePrice red> {FormattedCurrency(disc15Price)}</FramePrice>
                </PriceContainer>
              </>
            ) : (
              <FramePrice>{FormattedCurrency(frame15Price)}</FramePrice>
            )}
            {disc15BubbleCopy && disc15BubbleCopy !== 'NONE' && (
              <BlackFridayBubble fifteen={!selectedFifteenProduct}>
                {disc15BubbleCopy}
              </BlackFridayBubble>
            )}
          </FrameSelection>
        </FrameProductOptions>
      </CustomizationOption>
      {!selectedFifteenProduct && !IS_FOREIGN_COUNTRY && (
        <CustomizationOption>
          <Subheader>
            <Trans>Select frame color</Trans>
          </Subheader>
          {isOutOfStock && (
            <OutOfStock>
              <Trans>Out of stock</Trans>
            </OutOfStock>
          )}
          {DISPLAY_BOGO && (
            <>
              <RedSubheader>
                <Trans>Buy One, Get One 50%</Trans>
              </RedSubheader>
              <Bogo>
                Use code <span>BOGO</span> at checkout
              </Bogo>
            </>
          )}
          <FrameProductOptions>
            {FRAME_VARIANTS.map((variant) => (
              <ColorSelectionItem
                key={variant}
                variant={variant}
                onSelectColor={handleColorSelection}
                isOutOfStock={
                  contentfulCTAOverrides.edges[0].node[`en_10_frame_${variant}`] === 'Out of Stock'
                }
                isSelected={selectedColor === variant}
              />
            ))}
          </FrameProductOptions>
          <LimitedEdition>
            <Trans>Limited Edition</Trans>
          </LimitedEdition>
          <FrameProductOptions>
            {LIMITED_EDITION_VARIANTS.map((variant) => (
              <ColorSelectionItem
                key={variant}
                variant={variant}
                onSelectColor={handleColorSelection}
                isOutOfStock={
                  contentfulCTAOverrides.edges[0].node[`en_10_frame_${variant}`] === 'Out of Stock'
                }
                isSelected={selectedColor === variant}
              />
            ))}
          </FrameProductOptions>
        </CustomizationOption>
      )}
      <>
        {!IS_FOREIGN_COUNTRY ? (
          <>
            {!isOutOfStock && (
              <CustomizationOption>
                <Subheader>
                  <Trans>Quantity</Trans>
                </Subheader>
                <Quantity>
                  <QuantityInput>
                    <QuantityButton type="button" onClick={decrementQuantity}>
                      -
                    </QuantityButton>
                    {quantityVal}
                    <QuantityButton type="button" onClick={incrementQuantity}>
                      +
                    </QuantityButton>
                  </QuantityInput>
                </Quantity>
              </CustomizationOption>
            )}
          </>
        ) : (
          <>
            <InternationalQuantity>
              <InternationalQuantityLabel htmlFor="quantity-input">
                <Trans>Quantity</Trans>
              </InternationalQuantityLabel>
              <InternationalQuantityInput>
                <QuantityButton type="button" onClick={decrementQuantity}>
                  -
                </QuantityButton>
                {quantityVal}
                <QuantityButton type="button" onClick={incrementQuantity}>
                  +
                </QuantityButton>
              </InternationalQuantityInput>
            </InternationalQuantity>
          </>
        )}
      </>
      <CtaContainer showBwpCtaFirst={showBwpCtaFirst}>
        <ButtonContainer className="regular-cta" fullWidth={!IS_FOREIGN_COUNTRY}>
          <AddToCartButton
            label={t(CTA_VALUE)}
            adding={submitting}
            handleClick={() => handleAddToCart(productInfo.frame.shopifyId, quantityVal)}
            oos={isOutOfStock}
          />
        </ButtonContainer>
        {DisplayBWP && (
          <BuyWithPrimeContainer
            visible={DISPLAY_BUY_WITH_PRIME}
            isFixed={isFixed && BUY_WITH_PRIME_INFO.show_sticky_cta}
          >
            <div
              id="amzn-buy-now"
              data-site-id="pe1h2oqj31"
              data-widget-id="w-PycpPvXHix3e1fXLJFDNc4"
              data-sku={selectedSku}
            />
          </BuyWithPrimeContainer>
        )}
      </CtaContainer>
      {showStickyButton &&
        !IS_FOREIGN_COUNTRY &&
        isMobile &&
        !isOutOfStock &&
        (!BUY_WITH_PRIME_INFO.show_sticky_cta ||
          (BUY_WITH_PRIME_INFO.show_sticky_cta && !DISPLAY_BUY_WITH_PRIME)) && (
          <StickyButton
            label={t(CTA_VALUE)}
            onClick={() => handleAddToCart(productInfo.frame.shopifyId, quantityVal)}
            isVisible={showStickyButton}
            oos={isOutOfStock}
          />
        )}
      {!IS_FOREIGN_COUNTRY && !isOutOfStock && !isPreorder && <ShippingDetails frame />}
    </>
  )
}

FrameProductForm.propTypes = {
  product: PropTypes.object.isRequired,
  simplifiedProductTitle: PropTypes.string.isRequired,
  handle: PropTypes.string.isRequired,
  title: PropTypes.string,
  frame15: PropTypes.object.isRequired,
  frameBlack: PropTypes.object.isRequired,
  frameWhite: PropTypes.object.isRequired,
  frameSilver: PropTypes.object.isRequired,
  frameGold: PropTypes.object.isRequired,
  framePoppy: PropTypes.object.isRequired,
  isLargerDevice: PropTypes.func.isRequired,
  isPlusProduct: PropTypes.func.isRequired,
  setShownImageIndex: PropTypes.func.isRequired,
  updateSelectedColor: PropTypes.func.isRequired,
}

export default FrameProductForm
